$(document).ready(function () {

  $('#nav-novelty-slider').slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 4,
    infinite: true,
    arrows: true,
    prevArrow: '<span class="slick-prev slick-arrow"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-prev slick-arrow"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 524.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  $(window).resize(function () {
    $('#nav-novelty-slider').not('.slick-initialized').slick('resize');

  });

  $(window).on('orientationchange', function () {
    $('#nav-novelty-slider').not('.slick-initialized').slick('resize');
  });
});

$(document).ready(function () {
  $('#nav-top-sales').slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 4,
    infinite: true,
    arrows: true,
    prevArrow: '<span class="slick-prev slick-arrow"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-prev slick-arrow"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 524.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  $(window).resize(function () {
    $('#nav-top-sales').not('.slick-initialized').slick('resize');
  });

  $(window).on('orientationchange', function () {
    $('#nav-top-sales').not('.slick-initialized').slick('resize');
  });
});
